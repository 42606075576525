import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from "@angular/core";
import { UserService } from "src/app/shared/generated/api/user.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ColDef } from "ag-grid-community";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { AgGridAngular } from "ag-grid-angular";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";

declare var $: any;

@Component({
    selector: "pto-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit, OnDestroy {
    @ViewChild("usersGrid") usersGrid: AgGridAngular;
    @ViewChild("unassignedUsersGrid") unassignedUsersGrid: AgGridAngular;

    private currentUser: UserDto;

    public canCreate: boolean;

    public rowData = [];
    columnDefs: ColDef[];
    defaultColDef;
    users: UserDto[];
    unassignedUsers: UserDto[];
    pinnedBottomRowData;
    frameworkComponents: any;

    userSubscription: Subscription;
    getUsersSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private dateColumnCreator: DateColumnCreatorService,
        private router: Router
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.UserID,
                        LinkDisplay: params.data.FullName,
                    };
                },
                cellRendererFramework: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/manage/users/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.FullName;
                },
                comparator: function (id1: any, id2: any) {
                    let link1 = id1.LinkDisplay;
                    let link2 = id2.LinkDisplay;
                    if (link1 < link2) {
                        return -1;
                    }
                    if (link1 > link2) {
                        return 1;
                    }
                    return 0;
                },
                flex: 4,
                tooltipField: "Name",
            },
            {
                headerName: "Email",
                field: "Email",
                flex: 4,
                tooltipField: "Email",
            },
            {
                headerName: "Role",
                field: "Role.RoleDisplayName",
                flex: 1,
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Role.RoleDisplayName",
                },
            },
            {
                headerName: "Receives System Communications?",
                field: "ReceiveSupportEmails",
                valueGetter: function (params) {
                    if (params.node != null && params.node.rowPinned) {
                        return null;
                    }
                    return params.data.ReceiveSupportEmails ? "Yes" : "No";
                },
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ReceiveSupportEmails",
                },
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef("Created", "CreateDate", "M/d/yyyy"),
            {
                headerName: "User ID",
                valueGetter: function (params: any) {
                    return params.node.rowPinned ? "Total: " + params.data.UserIDTotal : params.data.UserID;
                },
                pinnedRowCellRendererFramework: CustomPinnedRowRendererComponent,
                pinnedRowCellRendererParams: { filter: true },
                filter: "agNumberColumnFilter",
                width: 90,
            },
        ];

        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
        };
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;

            this.canCreate = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.UserRights, RightsEnum.Create);

            this.cdr.markForCheck();
        });
    }

    onUsersGridReady(gridEvent) {
        this.usersGrid?.api.showLoadingOverlay();

        this.getUsersSubscription = this.userService.usersGet().subscribe((users) => {
            this.rowData = users;
            this.users = users;

            this.unassignedUsers = users.filter((u) => {
                return u.Role.RoleID === RoleEnum.NoAccess && u.IsActive;
            });

            this.pinnedBottomRowData = [
                {
                    UserIDTotal: this.rowData.length,
                },
            ];

            this.usersGrid.api.sizeColumnsToFit();
            this.usersGrid.api.hideOverlay();
        });
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setPinnedBottomRowData([
            {
                UserIDTotal: gridEvent.api.getModel().rowsToDisplay.length,
            },
        ]);
    }

    inviteUser() {
        this.router.navigateByUrl("manage/users/invite");
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.getUsersSubscription?.unsubscribe();
        this.userSubscription?.unsubscribe();
    }
}
