import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from "./shared/pages";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { LoginCallbackComponent } from "./pages/login-callback/login-callback.component";
import { HelpComponent } from "./pages/help/help.component";
import { CreateUserCallbackComponent } from "./pages/create-user-callback/create-user-callback.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { CustomPageListComponent } from "./pages/custom-page-list/custom-page-list.component";
import { CustomPageDetailComponent } from "./pages/custom-page-detail/custom-page-detail.component";
import { CustomPageEditPropertiesComponent } from "./pages/custom-page-edit-properties/custom-page-edit-properties.component";
import { MsalGuard } from "@azure/msal-angular";
import { UnsavedChangesGuard } from "./shared/guards/unsaved-changes-guard";
import { DashboardManageComponent } from "./pages/dashboards/dashboard-manage/dashboard-manage.component";
import { UserInviteComponent } from "./pages/user-invite/user-invite.component";
import { ProjectListComponent } from "./pages/project/project-list/project-list.component";
import { ProjectCreateComponent } from "./pages/project/project-create/project-create.component";
import { ProjectDetailComponent } from "./pages/project/project-detail/project-detail.component";
import { ProjectDetailSummaryComponent } from "./pages/project/project-detail/project-detail-summary/project-detail-summary.component";
import { ProjectFileListComponent } from "./pages/project/project-detail/project-file-list/project-file-list.component";
import { ProjectCopyComponent } from './pages/project/project-copy/project-copy/project-copy.component';

const routes: Routes = [

  { path: "custom-pages/:vanity-url", component: CustomPageDetailComponent, canDeactivate: [UnsavedChangesGuard] },

  { path: "manage", component: DashboardManageComponent, canActivate: [MsalGuard], children: [
    { path: "users", component: UserListComponent },
    { path: "users/invite", component: UserInviteComponent },
    { path: "users/:id", component: UserDetailComponent },
    { path: "users/:id/edit", component: UserEditComponent },
    { path: "custom-pages", component: CustomPageListComponent, canActivate: [MsalGuard] },
    { path: "custom-pages/create", component: CustomPageEditPropertiesComponent, data: { create: true }, canDeactivate: [UnsavedChangesGuard] },
    { path: "custom-pages/edit-properties/:vanity-url", component: CustomPageEditPropertiesComponent, data: { create: false }, canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions/:id", component: FieldDefinitionEditComponent, canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions", component: FieldDefinitionListComponent },
  ] },
  { path: "projects", component: ProjectListComponent, canActivate: [MsalGuard] },
  {
    path: "projects/create", component: ProjectCreateComponent,
    canActivate: [MsalGuard],
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: "project/copy/:projectToCopyId",
    component: ProjectCopyComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "projects/:id",
    component: ProjectDetailComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        component: ProjectDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "file-list",
        component: ProjectFileListComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
    ]
  },
  { path: "help", component: HelpComponent },
  { path: "create-user-callback", component: CreateUserCallbackComponent },
  { path: "not-found", component: NotFoundComponent },
  { path: "subscription-insufficient", component: SubscriptionInsufficientComponent },
  { path: "unauthenticated", component: UnauthenticatedComponent },
  { path: "signin-oidc", component: LoginCallbackComponent },
  { path: "", component: HomeIndexComponent},
  { path: "**", component: NotFoundComponent }
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            relativeLinkResolution: "legacy",
            // Don't perform initial navigation in iframes
            initialNavigation: !isIframe ? "enabledBlocking" : "disabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
