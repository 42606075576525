import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Alert } from "../../models/alert";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { FieldDefinitionService } from "src/app/shared/generated/api/field-definition.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AlertService } from "../../services/alert.service";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { FieldDefinitionTypeEnum } from "../../generated/enum/field-definition-type-enum";
import { FieldDefinitionDto } from "../../generated/model/field-definition-dto";
import { PermissionEnum } from "../../generated/enum/permission-enum";
import { RightsEnum } from "../../models/enums/rights.enum";
import { Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";

declare var $: any;

@Component({
    selector: "field-definition",
    templateUrl: "./field-definition.component.html",
    styleUrls: ["./field-definition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldDefinitionComponent implements OnInit, OnDestroy {
    @Input() fieldDefinitionType: string;
    @Input() labelOverride: string;
    @Input() iconOnly: boolean = false;
    @Input() popperOptions?: any = {
        placement: "top",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: ({ placement, reference, popper }) => {
                        return [0, 5];
                    },
                },
            },
        ],
    };

    @ViewChild("p") public popover: any;
    @ViewChild("popContent") public content: any;

    fieldDefinition$: Observable<FieldDefinitionDto>;
    public fieldDefinition: FieldDefinitionDto;
    public isLoading: boolean = true;
    public isEditing: boolean = false;
    public emptyContent: boolean = false;

    public editedContent: string;
    public editor;

    currentUser: UserDto;
    userSubscription: Subscription;

    constructor(
        private fieldDefinitionService: FieldDefinitionService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        this.fieldDefinition$ = this.fieldDefinitionService.fieldDefinitionsFieldDefinitionTypeIDGet(FieldDefinitionTypeEnum[this.fieldDefinitionType]).pipe(
            map((result) => {
                this.loadFieldDefinition(result);
                return result;
            })
        );
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription.unsubscribe();
    }

    public getLabelText() {
        return this.labelOverride !== null && this.labelOverride !== undefined
            ? this.labelOverride
            : this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName;
    }

    public showEditButton(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.FieldDefinitionRights, RightsEnum.Update);
    }

    public enterEdit(event: any): void {
        this.editedContent = this.fieldDefinition.FieldDefinitionValue ?? "";
        this.isEditing = true;
        this.cdr.markForCheck();
    }

    public cancelEdit(): void {
        this.isEditing = false;
    }

    public saveEdit(): void {
        this.isEditing = false;
        this.isLoading = true;
        this.fieldDefinition.FieldDefinitionValue = this.editedContent;
        this.cdr.markForCheck();
        this.fieldDefinition$ = this.fieldDefinitionService
            .fieldDefinitionsFieldDefinitionTypeIDPut(this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeID, this.fieldDefinition)
            .pipe(
                map((result) => {
                    this.loadFieldDefinition(result);
                    return result;
                }),
                catchError((err: any) => {
                    this.isLoading = false;
                    this.alertService.pushAlert(new Alert("There was an error updating the field definition", AlertContext.Danger, true));
                    return err;
                })
            );
    }

    private loadFieldDefinition(fieldDefinition: FieldDefinitionDto) {
        this.fieldDefinition = fieldDefinition;
        this.emptyContent = fieldDefinition.FieldDefinitionValue?.length > 0 ? false : true;
        this.isLoading = false;
        this.cdr.markForCheck();
    }
}
