<ng-container *ngIf="species$ | async; else loading">
    <form class="form grid-10">
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Search Species</mat-label>
            <span matPrefix><mat-icon>search</mat-icon></span>
            <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto" />
            <button *ngIf="selectedSpecies" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectSpecies($event)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option.SpeciesCommonName + ", " }} <i>{{ option.SpeciesScientificName }}</i>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
    <mat-card *ngIf="selectedSpecies" style="margin-bottom: 1rem;">
        <mat-card-title>{{ selectedSpecies?.SpeciesCommonName }}</mat-card-title>
        <mat-card-subtitle
            ><i>{{ selectedSpecies?.SpeciesScientificName }}</i></mat-card-subtitle
        >
        <mat-card-content>
            <p><span style="font-weight: 500">Habitat(s):</span> {{ selectedSpecies?.GeneralHabitat || "-" }}</p>
            <p><span style="font-weight: 500">Habitat Description:</span> {{ selectedSpecies?.HabitatDescription || "-" }}</p>
            <p><span style="font-weight: 500">Micro Habitat:</span> {{ selectedSpecies?.MicroHab || "-" }}</p>
            <p><span style="font-weight: 500">Organism:</span> {{ selectedSpecies.OrganismType?.OrganismTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">Taxonomy:</span> {{ selectedSpecies.TaxonomyType?.TaxonomyTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">Blooming Season:</span> {{ selectedSpecies?.BloomingSeason || "-" }}</p>
            <p><span style="font-weight: 500">Federal Status:</span> {{ selectedSpecies.FederalStatusType?.FederalStatusTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">State Status:</span> {{ selectedSpecies.StateStatusType?.StateStatusTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">Other Status:</span> {{ selectedSpecies?.OtherStatus || "-" }}</p>
            <p><span style="font-weight: 500">CNPS Status:</span> {{ selectedSpecies?.CRPR || "-" }}</p>
        </mat-card-content>
    </mat-card>
</ng-container>
<ng-template #loading>
    <div class="text-center">
        <span class="fa fa-spinner loading-spinner"></span>
    </div>
</ng-template>
