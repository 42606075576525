import { Injectable } from "@angular/core";
import {
    Document,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableLayoutType,
    TableRow,
    TextRun,
    WidthType,
    convertInchesToTwip,
    AlignmentType,
    PageOrientation,
    TableBorders,
    BorderStyle,
    Header,
    VerticalAlign,
} from "docx";
import { saveAs } from "file-saver";
import { SpeciesDto } from "../generated/model/species-dto";
import { ProjectSpeciesTableRowDto } from "../generated/model/project-species-table-row-dto";
import * as moment from "moment";

@Injectable({
    providedIn: "root",
})
export class GenerateWordDocService {
    defaultMargins = {
        top: convertInchesToTwip(0.04),
        bottom: convertInchesToTwip(0.04),
        right: convertInchesToTwip(0.08),
        left: convertInchesToTwip(0.08),
    };

    rowHeaders = [
        "Organism",
        "Taxonomy",
        "Common Name Scientific Name",
        "Status Federal/ State",
        "CNPS Status",
        "Other Status",
        "Habitat",
        "Identification/Survey Period",
        "Potential to Occur",
    ];

    statusCodeHeaders = ["Federal", "State", "Other"];

    font: string = "Arial";

    constructor() {}

    tableHeaderCell = (text: string, tableType: string) => {
        if (tableType === "pto") {
            if (text === "Common Name Scientific Name") {
                return new TableCell({
                    verticalAlign: VerticalAlign.BOTTOM,
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "Common Name",
                                    bold: true,
                                    font: this.font,
                                    size: 16,
                                }),
                                new TextRun({
                                    text: "Scientific Name",
                                    bold: true,
                                    italics: true,
                                    font: this.font,
                                    break: 1,
                                    size: 16,
                                }),
                            ],
                        }),
                    ],
                });
            }

            if (text === "Identification/Survey Period") {
                return new TableCell({
                    verticalAlign: VerticalAlign.BOTTOM,
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "Identification/",
                                    bold: true,
                                    font: this.font,
                                    size: 16,
                                }),
                                new TextRun({
                                    text: "Survey Period",
                                    bold: true,
                                    font: this.font,
                                    break: 1,
                                    size: 16,
                                }),
                            ],
                        }),
                    ],
                });
            }

            return new TableCell({
                verticalAlign: VerticalAlign.BOTTOM,
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text,
                                bold: true,
                                font: this.font,
                                size: 16,
                            }),
                        ],
                    }),
                ],
            });
        }

        if (tableType === "statusCode") {
            return new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text,
                                bold: true,
                                font: this.font,
                                size: 14,
                            }),
                        ],
                    }),
                ],
            });
        }
    };

    getHeaderRows = (data: any, tableType: string) => {
        const rows = [];

        data.forEach((x: any) => {
            rows.push(this.tableHeaderCell(x, tableType));
        });

        return rows;
    };

    getParagraph = (text: string | null) => {
        return new Paragraph({
            text: text || "-",
        });
    };

    tableCell = (text: string | null) => {
        return new TableCell({
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: text || "-",
                            font: this.font,
                            size: 16,
                        }),
                    ],
                }),
            ],
        });
    };

    potentialToOccurCell = (likelihood: string | undefined, text: string | undefined) => {
        return new TableCell({
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: likelihood ? `${likelihood}. ` : "",
                            bold: true,
                            font: this.font,
                            size: 16,
                        }),
                        new TextRun({
                            text: text || "",
                            font: this.font,
                            size: 16,
                        }),
                    ],
                }),
            ],
        });
    };

    speciesNameCell = (commonName: string, scientificName: string) => {
        return new TableCell({
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: commonName || "-",
                            font: this.font,
                            size: 16,
                        }),
                        new TextRun({
                            text: scientificName || "-",
                            italics: true,
                            font: this.font,
                            break: 1,
                            size: 16,
                        }),
                    ],
                }),
            ],
        });
    };

    foundTypesRowSpan(data: ProjectSpeciesTableRowDto[], previousType: string) {
        const foundTypes = data.filter((x) => x.Species.TaxonomyType.TaxonomyTypeName === previousType);
        return foundTypes.length;
    }

    commonSpeciesCellData(species: SpeciesDto, projectSpeciesTableRow: ProjectSpeciesTableRowDto) {
        let fedStatus = species.FederalStatusType.FederalStatusTypeName;
        let stateStatus = species.StateStatusType.StateStatusTypeName;
        return [
            this.speciesNameCell(species.SpeciesCommonName, species.SpeciesScientificName),
            this.tableCell((fedStatus === "NL" ? "-" : species.FederalStatusType.FederalStatusTypeName) + "/" + (stateStatus === "NL" ? "-" : stateStatus)),
            this.tableCell(species.CRPR),
            this.tableCell(species.OtherStatus),
            this.tableCell(species.HabitatDescription),
            this.tableCell(species.BloomingSeason),
            this.potentialToOccurCell(projectSpeciesTableRow.Likelihood.LikelihoodDisplayName, projectSpeciesTableRow.Description),
        ];
    }

    formatPtoTable(gridData: ProjectSpeciesTableRowDto[]) {
        const wildlife = gridData.filter((x: ProjectSpeciesTableRowDto) => x.Species.OrganismType.OrganismTypeName === "Wildlife");
        const plants = gridData.filter((x: ProjectSpeciesTableRowDto) => x.Species.OrganismType.OrganismTypeName === "Plant");

        const getOranismGroupedRows = (rowData: ProjectSpeciesTableRowDto[], organismType: string) => {
            const rows = [];

            const updatedData = rowData;
            let previousType = "";

            rowData.forEach((projectSpecies: any, i: number) => {
                const currentSpecies = projectSpecies.Species;

                if (i === 0) {
                    previousType = currentSpecies.TaxonomyType.TaxonomyTypeName;

                    rows.push(
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: organismType,
                                                    font: this.font,
                                                    size: 16,
                                                }),
                                            ],
                                        }),
                                    ],
                                    rowSpan: rowData.length,
                                    verticalAlign: "center",
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: currentSpecies.TaxonomyType.TaxonomyTypeDisplayName,
                                                    font: this.font,
                                                    size: 16,
                                                }),
                                            ],
                                        }),
                                    ],
                                    rowSpan: this.foundTypesRowSpan(updatedData, previousType),
                                    verticalAlign: "center",
                                }),
                                ...this.commonSpeciesCellData(currentSpecies, projectSpecies),
                            ],
                        })
                    );
                    return;
                }

                if (previousType !== currentSpecies.TaxonomyType.TaxonomyTypeName) {
                    previousType = currentSpecies.TaxonomyType.TaxonomyTypeName;

                    rows.push(
                        new TableRow({
                            cantSplit: true,
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: currentSpecies.TaxonomyType.TaxonomyTypeDisplayName,
                                                    font: this.font,
                                                    size: 16,
                                                }),
                                            ],
                                        }),
                                    ],
                                    rowSpan: this.foundTypesRowSpan(updatedData, previousType),
                                    verticalAlign: "center",
                                }),
                                ...this.commonSpeciesCellData(currentSpecies, projectSpecies),
                            ],
                        })
                    );

                    return;
                } else {
                    previousType = currentSpecies.TaxonomyType.TaxonomyTypeName;

                    rows.push(
                        new TableRow({
                            cantSplit: true,
                            children: [...this.commonSpeciesCellData(currentSpecies, projectSpecies)],
                        })
                    );

                    return;
                }
            });

            return rows;
        };

        const borders = {
            left: {
                style: BorderStyle.NONE,
            },
            right: {
                style: BorderStyle.NONE,
            },
        };

        return [
            new Table({
                borders,
                width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                },
                layout: TableLayoutType.AUTOFIT,
                margins: this.defaultMargins,
                rows: [
                    new TableRow({
                        tableHeader: true,
                        children: [...this.getHeaderRows(this.rowHeaders, "pto")],
                    }),
                    ...getOranismGroupedRows(plants, "Plants"),
                    ...getOranismGroupedRows(wildlife, "Wildlife"),
                ],
            }),
        ];
    }

    statusCodeTable() {
        // NOTE: this is static data for now, when user's can manage species we should pull this list from the db

        const federalStatus = [
            "Candidate = FC",
            "Delisted = FD",
            "Endangered = FE",
            "None = -",
            "Proposed Endangered = FPE",
            "Proposed Threatened = FPT",
            "Threatened = FT",
        ];

        const californiaStatus = [
            "Candidate Endangered = CCE",
            "Candidate Threatened = CCT",
            "Delisted = CD",
            "Endangered = CE",
            "None = -",
            "Rare = CR",
            "Threatened = CT",
        ];

        const cnpsRankCategories = [
            "1A = Plants presumed extirpated in California and either rare or extinct elsewhere",
            "1B = Plants Rare, Threatened, or Endangered in California and elsewhere",
            "2A = Plants presumed extirpated in California, but more common elsewhere",
            "2B = Plants Rare, Threatened, or Endangered in California, but more common elsewhere",
            "3 = Plants about which more information is needed - A Review List",
            "4 = Plants of limited distribution - A Watch List",
        ];

        const cnpsCodeExtensions = [
            ".1 = Seriously endangered in California (over 80% of occurrences threatened/high degree and immediacy of threat)",
            ".2 = Fairly endangered in California (20U+002d80% occurrences threatened)",
            ".3 = Not very endangered in California (less than 20% of occurrences threatened or no current threats known)",
        ];

        const getStatusRowData = () => {
            const otherStatusBuilder = (data: string[], header: string) => {
                const paragraphs = [];

                data.forEach((text, i) => {
                    if (i === 0) {
                        paragraphs.push(
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: header,
                                        font: this.font,
                                        size: 14,
                                        bold: true,
                                    }),
                                ],
                            })
                        );
                    }
                    paragraphs.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text,
                                    font: this.font,
                                    size: 14,
                                }),
                            ],
                        })
                    );
                });

                return paragraphs;
            };

            return [
                new TableRow({
                    children: [
                        new TableCell({
                            children: federalStatus.map((text) => {
                                return new Paragraph({
                                    children: [
                                        new TextRun({
                                            text,
                                            font: this.font,
                                            size: 14,
                                        }),
                                    ],
                                });
                            }),
                        }),
                        new TableCell({
                            children: californiaStatus.map((text) => {
                                return new Paragraph({
                                    children: [
                                        new TextRun({
                                            text,
                                            font: this.font,
                                            size: 14,
                                        }),
                                    ],
                                });
                            }),
                        }),
                        new TableCell({
                            children: [
                                ...otherStatusBuilder(cnpsRankCategories, "CNPS Rank Categories:"),
                                new Paragraph(""),
                                ...otherStatusBuilder(cnpsCodeExtensions, "CNPS Code Extensions:"),
                            ],
                        }),
                    ],
                }),
            ];
        };

        const borders = {
            top: {
                style: BorderStyle.NONE,
            },
            bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "000000",
            },
            left: {
                style: BorderStyle.NONE,
            },
            right: {
                style: BorderStyle.NONE,
            },
        };

        return [
            new Table({
                borders: TableBorders.NONE,
                width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                },
                layout: TableLayoutType.AUTOFIT,
                margins: this.defaultMargins,
                rows: [
                    new TableRow({
                        children: [...this.getHeaderRows(this.statusCodeHeaders, "statusCode")],
                    }),
                    ...getStatusRowData(),
                    new TableRow({
                        children: [
                            new TableCell({
                                borders,
                                columnSpan: 3,
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: "Sources: CNPS 2020; USFWS 2020; CDFW 2020",
                                                font: this.font,
                                                size: 14,
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            }),
        ];
    }

    titleandHeader() {
        return [
            new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                    new TextRun({
                        text: "TABLE XX",
                        bold: true,
                        font: this.font,
                        size: 24,
                    }),
                    new TextRun({
                        text: "SPECIAL-STATUS SPECIES CONSIDERED IN THE PROJECT AREA",
                        bold: true,
                        break: 1,
                        font: this.font,
                        size: 24,
                    }),
                ],
            }),
        ];
    }

    wordDocument(gridData: ProjectSpeciesTableRowDto[]) {
        const doc = new Document({
            sections: [
                {
                    properties: {
                        titlePage: true,
                        page: {
                            size: {
                                orientation: PageOrientation.LANDSCAPE,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: this.titleandHeader(),
                        }),
                    },
                    children: [
                        ...this.titleandHeader(),
                        ...this.formatPtoTable(gridData),
                        new Paragraph({
                            text: "",
                        }),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "KEY TO STATUS CODES:",
                                    font: this.font,
                                    size: 14,
                                }),
                            ],
                        }),
                        ...this.statusCodeTable(),
                    ],
                },
            ],
        });
        this.saveWordDoc(doc);
    }

    saveWordDoc(doc: any) {
        Packer.toBlob(doc).then((blob) => {
            const date = moment(new Date()).format("YYYY-MM-DD");
            saveAs(blob, `${date}-PTO-Table.docx`);
        });
    }
}
