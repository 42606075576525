import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/shared/generated/api/user.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Observable, Subscription } from "rxjs";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { UserUpdateDto } from "src/app/shared/models/user-update-dto";
import { RoleDto } from "src/app/shared/generated/model/models";
import { map } from "rxjs/operators";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";

@Component({
    selector: "pto-user-edit",
    templateUrl: "./user-edit.component.html",
    styleUrls: ["./user-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;

    public userID: number;
    public user: UserDto;
    public model: UserUpdateDto;
    public roles: Array<RoleDto>;
    public isLoadingSubmit: boolean = false;

    currentUserSubscription: Subscription;
    updateUserSubscription: Subscription;
    user$: Observable<UserDto>;
    roles$: Observable<RoleDto[]>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private roleService: RoleService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        this.userID = parseInt(this.route.snapshot.paramMap.get("id"));

        if (!this.userID) return;

        this.user$ = this.userService.usersUserIDGet(this.userID).pipe(
            map((data) => {
                this.user = data instanceof Array ? null : (data as UserDto);
                this.model = new UserUpdateDto();
                this.model.RoleID = this.user.Role.RoleID;
                this.model.ReceiveSupportEmails = this.user.ReceiveSupportEmails;
                this.cdr.markForCheck();
                return this.user;
            })
        );

        this.roles$ = this.roleService.rolesGet().pipe(
            map((data) => {
                const roles = data.sort((a: RoleDto, b: RoleDto) => {
                    if (a.RoleDisplayName > b.RoleDisplayName) return 1;
                    if (a.RoleDisplayName < b.RoleDisplayName) return -1;
                    return 0;
                });
                return roles;
            })
        );
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.updateUserSubscription?.unsubscribe();
    }

    cancel() {
        this.router.navigateByUrl(`manage/users/${this.userID}`);
    }

    onSubmit(editUserForm: HTMLFormElement): void {
        this.isLoadingSubmit = true;

        this.updateUserSubscription = this.userService.usersUserIDPut(this.userID, this.model).subscribe(
            (response) => {
                this.isLoadingSubmit = false;
                this.router.navigateByUrl("/manage/users/" + this.userID).then((x) => {
                    this.alertService.pushAlert(new Alert("The user was successfully updated.", AlertContext.Success));
                    if (this.currentUser.UserID == this.userID) {
                        this.authenticationService.refreshUser();
                    }
                    this.cdr.markForCheck();
                });
            },
            (error) => {
                this.isLoadingSubmit = false;
                this.cdr.detectChanges();
            }
        );
    }

    checkReceiveSupportEmails(): void {
        if (this.model.RoleID != RoleEnum.Admin) {
            this.model.ReceiveSupportEmails = false;
            this.cdr.markForCheck();
        }
    }
}
