import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "pto-confirm-dialog",
    templateUrl: "confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
