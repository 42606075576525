import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
    selector: "pto-login-callback",
    templateUrl: "./login-callback.component.html",
    styleUrls: ["./login-callback.component.scss"],
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
    userSubscription: Subscription;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit() {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.router.navigate(["/"]);
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }
}
