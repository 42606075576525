/**
 * Pto.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserDto } from '././user-dto';
import { ProjectSpeciesTableRowDto } from '././project-species-table-row-dto';

export class ProjectDto { 
    ProjectID?: number;
    ProjectName?: string;
    ProjectNumber?: string;
    Tags?: string;
    ProjectSpeciesTableRows?: Array<ProjectSpeciesTableRowDto>;
    CreateDate?: string;
    CreateUser?: UserDto;
    UpdateDate?: string;
    UpdateUser?: UserDto;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
