<ng-contianer *ngIf="selectedSpecies">
    <h2 mat-dialog-title>Species Details</h2>
    <mat-card>
        <mat-card-title>{{ selectedSpecies?.SpeciesCommonName }}</mat-card-title>
        <mat-card-subtitle
            ><i>{{ selectedSpecies?.SpeciesScientificName }}</i></mat-card-subtitle
        >
        <mat-card-content>
            <p><span style="font-weight: 500">Habitat(s):</span> {{ selectedSpecies?.GeneralHabitat || "-" }}</p>
            <p><span style="font-weight: 500">Habitat Description:</span> {{ selectedSpecies?.HabitatDescription || "-" }}</p>
            <p><span style="font-weight: 500">Micro Habitat:</span> {{ selectedSpecies?.MicroHab || "-" }}</p>
            <p><span style="font-weight: 500">Organism:</span> {{ selectedSpecies.OrganismType?.OrganismTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">Taxonomy:</span> {{ selectedSpecies.TaxonomyType?.TaxonomyTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">Blooming Season:</span> {{ selectedSpecies?.BloomingSeason || "-" }}</p>
            <p><span style="font-weight: 500">Federal Status:</span> {{ selectedSpecies.FederalStatusType?.FederalStatusTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">State Status:</span> {{ selectedSpecies.StateStatusType?.StateStatusTypeDisplayName || "-" }}</p>
            <p><span style="font-weight: 500">Other Status:</span> {{ selectedSpecies?.OtherStatus || "-" }}</p>
            <p><span style="font-weight: 500">CNPS Status:</span> {{ selectedSpecies?.CRPR || "-" }}</p>
        </mat-card-content>
    </mat-card>
    <mat-dialog-actions align="end">
        <esa-material-button [mat-dialog-close]="true" label="Close" type="clear" icon="close"></esa-material-button>
    </mat-dialog-actions>
</ng-contianer>
