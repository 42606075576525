<div class="detail-view" *ngIf="user$ | async as user">
    <div class="page-header pb-3">
        <h1 class="page-title">
            {{ user?.FullName || (user?.FirstName || user?.LastName ? user?.FirstName + " " + user?.LastName : null) }}
        </h1>
    </div>

    <form class="form grid-12" (ngSubmit)="onSubmit(editUserForm)" #editUserForm="ngForm">
        <div class="g-col-12">
            <esa-material-ff
                keyStyle="20%"
                name="RoleID"
                [editMode]="true"
                [required]="true"
                label="Role"
                type="dropdown"
                [options]="roles$ | async"
                optionLabel="RoleDisplayName"
                optionValue="RoleID"
                (ngModelChange)="checkReceiveSupportEmails()"
                [(ngModel)]="model.RoleID">
            </esa-material-ff>

            <div class="kv-pair" *ngIf="model.RoleID?.toString() === '1'">
                <pto-custom-form-label label="Should Receive System Communications?"></pto-custom-form-label>
                <div class="value">
                    <mat-radio-group
                        aria-label="Should Receive System Communications?"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="model.ReceiveSupportEmails"
                        required>
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false"> No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <div class="required-note g-col-8"><i class="fas fa-bolt"></i> Required field</div>

        <div class="g-col-12 actions-bar" style="margin-top: 1rem">
            <esa-material-button
                type="primary"
                label="Save"
                icon="save"
                [tooltip]="!editUserForm.form.valid ? 'You are missing requried fields.' : ''"
                (click)="onSubmit(editUserForm)"
                [disabled]="!editUserForm.form.valid || isLoadingSubmit"
                style="margin-right: 1rem">
            </esa-material-button>
            <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancel()"> </esa-material-button>
        </div>
    </form>
</div>
