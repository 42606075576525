import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ProjectFormComponent } from "../shared/project-form/project-form.component";
import { CurrentProjectService } from "src/app/services/current-project.service";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { ProjectUpsertDto } from "src/app/shared/generated/model/project-upsert-dto";

@Component({
    selector: "pto-project-create",
    templateUrl: "./project-create.component.html",
    styleUrls: ["./project-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createNewProjectForm", { read: ProjectFormComponent })
        
    form: ProjectFormComponent;
    public project: ProjectDto = {};
    public projectUpsertDto: ProjectUpsertDto;
    private projectResponse: any;

    constructor(
        private currentProjectService: CurrentProjectService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.projectUpsertDto = this.currentProjectService.createProjectDto(this.project);
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    save(result: any): void {
        if (!!result) {
            this.projectResponse = result;
            this.router.navigate([`/projects/${result.ProjectID}`]);
        } else {
            this.cdr.detectChanges();
        }
    }

    cancelEdit() {
        this.router.navigateByUrl("/projects");
    }

    canExit() {
        if (this.projectResponse) {
            return true;
        } else {
            return JSON.stringify(this.projectUpsertDto) === JSON.stringify(this.form.projectUpsertDto);
        }
    }
}
