import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CustomPageService } from "src/app/shared/generated/api/custom-page.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { CustomPageDto, CustomPageUpsertDto } from "src/app/shared/generated/model/models";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { EditorComponent } from "@tinymce/tinymce-angular";
import TinyMCEHelpers from "src/app/shared/helpers/tiny-mce-helpers";
import { Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Component({
    selector: "pto-custom-page-detail",
    templateUrl: "./custom-page-detail.component.html",
    styleUrls: ["./custom-page-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPageDetailComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild("tinyMceEditor") tinyMceEditor: EditorComponent;
    public tinyMceConfig: object;

    @Input() customPageVanityUrl: string;
    public customPageContent: SafeHtml;
    public viewableRoleIDs: Array<number>;
    public isLoading: boolean = true;
    public isEditing: boolean = false;
    public isEmptyContent: boolean = false;

    public editor;
    public editedContent: string;
    public originalContent: string;

    private currentUser: UserDto;

    public customPage: CustomPageDto;
    public customPage$: Observable<any>;

    userSubscription: Subscription;
    customRolesSubcription: Subscription;

    constructor(
        private customPageService: CustomPageService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef
    ) {
        // force route reload whenever params change
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngAfterViewChecked() {
        // viewChild is updated after the view has been checked
        this.initalizeEditor();
    }

    initalizeEditor() {
        if (!this.isLoading && this.isEditing) {
            this.tinyMceConfig = TinyMCEHelpers.DefaultInitConfig(this.tinyMceEditor);
        }
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        const vanityUrl = this.route.snapshot.paramMap.get("vanity-url");

        if (vanityUrl) {
            this.customPage$ = this.customPageService.customPagesGetByUrlCustomPageVanityURLGet(vanityUrl).pipe(
                map((customPage) => {
                    this.customPage = customPage;
                    this.customPageContent = this.sanitizer.bypassSecurityTrustHtml(customPage.CustomPageContent);
                    this.isEmptyContent = customPage.IsEmptyContent;
                    this.originalContent = customPage.CustomPageContent;
                    this.editedContent = this.originalContent;
                    this.isLoading = false;
                    return customPage;
                })
            );

            this.customRolesSubcription = this.customPageService.customPagesGetByUrlCustomPageVanityURLRolesGet(vanityUrl).subscribe((pageRoleDtos) => {
                this.viewableRoleIDs = pageRoleDtos.map((pageRole) => pageRole.RoleID);
                this.cdr.markForCheck();
            });
        }
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription.unsubscribe();
        this.customRolesSubcription.unsubscribe();
    }

    canExit() {
        return !this.isEditing || this.originalContent == this.editedContent;
    }

    public canReadCustomPages(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Read);
    }

    public showEditButton(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Update);
    }

    public enterEdit(): void {
        this.isEditing = true;
    }

    public cancelEdit(): void {
        this.isEditing = false;
    }

    public saveEdit(): void {
        this.isEditing = false;
        this.isLoading = true;
        const updateDto = new CustomPageUpsertDto({
            CustomPageContent: this.editedContent,
            CustomPageDisplayName: this.customPage.CustomPageDisplayName,
            CustomPageVanityUrl: this.customPage.CustomPageVanityUrl,
            MenuItemID: this.customPage.MenuItem.MenuItemID,
            ViewableRoleIDs: this.viewableRoleIDs,
        });

        this.customPage$ = this.customPageService.customPagesCustomPageIDPut(this.customPage.CustomPageID, updateDto).pipe(
            map(
                (response) => {
                    this.customPage = response;
                    this.customPageContent = this.sanitizer.bypassSecurityTrustHtml(response.CustomPageContent);
                    this.editedContent = response.CustomPageContent;
                    this.isEmptyContent = response.IsEmptyContent;
                    this.isLoading = false;
                    return response;
                },
                catchError((err) => {
                    this.isLoading = false;
                    this.alertService.pushAlert(new Alert("There was an error updating the rich text content", AlertContext.Danger, true));
                    return err;
                })
            )
        );
    }
}
