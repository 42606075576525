import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { PermissionEnum } from "../shared/generated/enum/permission-enum";
import { RightsEnum } from "../shared/models/enums/rights.enum";
import { AuthenticationService } from "./authentication.service";
import { ProjectDto } from "../shared/generated/model/project-dto";
import { ProjectUpsertDto } from "../shared/generated/model/project-upsert-dto";
import { UserDto } from "../shared/generated/model/user-dto";

@Injectable({
    providedIn: "root",
})
export class CurrentProjectService {
    currentProject$ = new BehaviorSubject<ProjectDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentProject(project: ProjectDto): void {
        this.currentProject$.next(project);
    }

    getCurrentProject(): Observable<any> {
        return this.currentProject$.asObservable();
    }

    createProjectDto(project: ProjectDto) {
        const projectDto = new ProjectUpsertDto({
            ProjectName: project?.ProjectName,
            ProjectNumber: project?.ProjectNumber,
            Tags: project?.Tags,
            ProjectSpeciesToAdd: [],
            ProjectSpeciesToUpdate: [],
            ProjectSpeciesToDelete: [],
        });
        return projectDto;
    }

    canEditCurrentProject(currentUser: UserDto, project: ProjectDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.ProjectRights, RightsEnum.Update);
    }
}
