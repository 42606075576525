/**
 * Pto.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProjectSpeciesTableRowUpsertDto } from '././project-species-table-row-upsert-dto';

export class ProjectUpsertDto { 
    ProjectName: string;
    ProjectNumber?: string;
    Tags?: string;
    ProjectSpeciesToAdd?: Array<ProjectSpeciesTableRowUpsertDto>;
    ProjectSpeciesToUpdate?: Array<ProjectSpeciesTableRowUpsertDto>;
    ProjectSpeciesToDelete?: Array<ProjectSpeciesTableRowUpsertDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
