import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { MaterialChipsRendererComponent } from "src/app/shared/components/ag-grid/material-chips-renderer/material-chips-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
@Component({
    selector: "pto-project-list",
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListComponent implements OnInit {
    @ViewChild("projectsGrid") projectsGrid: AgGridAngular;

    public currentUser: UserDto;
    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;

    frameworkComponents: any;
    searchGridValue: string;
    isActive: boolean = false;

    user: Subscription;
    getProjectRequest: Subscription;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router,
    ) {
        let component = this;
        this.frameworkComponents = {
            buttonRenderer: ButtonRendererComponent,
        };

        this.columnDefs = [
            {
                headerName: "Copy",
                headerTooltip: "Copy",
                field: "Copy",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: function (params) {
                        component.copyProject(params);
                    },
                    icon: "content_copy",
                },
                cellStyle: (_params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 100,
            },
            {
                headerName: "Project Name",
                headerTooltip: "Project Name",
                field: "ProjectName",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ProjectID,
                        LinkDisplay: params.data.ProjectName,
                    };
                },
                cellRendererFramework: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/projects/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ProjectName;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                minWidth: 300,
                sort: "asc",
            },
            {
                headerName: "Project Number",
                headerTooltip: "Project Number",
                field: "ProjectNumber",
                minWidth: 200,
            },
            {
                headerName: "Tags",
                headerTooltip: "Tags",
                field: "Tags",
                cellRendererFramework: MaterialChipsRendererComponent,
                cellRendererParams: function (params: any) {
                    return {
                        Tags: params.data.$TagsAsJSON,
                    };
                },
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "$TagsAsJSON",
                    fieldPropertyDisplay: "Name",
                    columnContainsMultipleValues: true,
                },
                minWidth: 200,
                flex: 1,
            },
            {
                headerName: "Created By",
                headerTooltip: "Created By",
                field: "CreateUser.FullName",
                minWidth: 100,
            },
            this.dateColumnCreator.createDateColumnDef("Date Created", "CreateDate", "M/dd/YYYY"),
        ];

        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
        };
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getProjectRequest?.unsubscribe();
    }

    copyProject(rowParams) {
        this.router.navigate([`/project/copy/${rowParams.rowData.ProjectID}`]);
    }

    navigateToProjectCreatePage() {
        this.router.navigate(["/projects/create"]);
    }

    searchGrid() {
        this.projectsGrid.api.setQuickFilter(this.searchGridValue);
        this.cdr.markForCheck();
    }

    resetGrid() {
        this.projectsGrid.api.setQuickFilter("");
        this.searchGridValue = "";
        this.cdr.markForCheck();
    }

    resetFilter() {
        this.isActive = false;
        this.projectsGrid?.api?.setFilterModel(null);
        this.cdr.markForCheck();
    }

    filterMyProjects(): void {
        this.isActive = true;
        let userFilterComponent = this.projectsGrid.api.getFilterInstance("CreateUser.FullName");

        userFilterComponent.setModel({
            type: "equals",
            filter: this.currentUser.FullName,
        });
        this.projectsGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }

    onProjectsGridReady(gridEvent) {
        this.projectsGrid.api.showLoadingOverlay();

        this.getProjectRequest = this.projectService.projectsGet().subscribe((results) => {
            this.rowData = results;
            this.rowData.forEach((rd) => {
                rd.$TagsAsJSON = rd.Tags ? JSON.parse(rd.Tags) : [];
            });
            this.projectsGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }
}
