<div class="main">
    <div class="detail-view">
        <div class="page-header flex-between">
            <h1 class="page-title">New Project</h1>
        </div>

        <ng-container *ngIf="sourceProject$ | async as p">
            <p>Create a new project from {{ p.ProjectName }}</p>

            <pto-project-form
                #copyProjectForm
                [project]="project"
                [editMode]="true"
                [isCopyMode]="true"
                rowSelection="single"
                (formSubmitted)="save($event)"
                (cancelEditModeChange)="cancelEdit()"></pto-project-form>

            <div class="meta-data">
                <h4>Source Project Meta Data</h4>
                <div class="md-copy">
                    <p>Project Name: {{ p.ProjectName }}</p>
                    <p>Project Number: {{ p.ProjectNumber }}</p>
                    <p>Date Created: {{ p.CreateDate | date }}</p>
                    <p>Created by: {{ p.CreateUser?.FullName }}</p>
                    <p *ngIf="p.UpdateDate">Last Updated: {{ p.UpdateDate | date }}</p>
                    <p *ngIf="p.UpdateUser">Updated by: {{ p.UpdateUser?.FullName }}</p>
                </div>
            </div>
        </ng-container>
    </div>
</div>
