<div class="detail-view">
    <div class="page-header flex-between">
        <h1 class="page-title">{{ project?.ProjectName }}</h1>
        <div *ngIf="!hasReadOnly()" matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
            <esa-material-button
                *ngIf="canDelete()"
                icon="delete"
                type="clear"
                (click)="delete()"
                [matTooltip]="editMode ? '' : 'Delete Project'"
                [disabled]="editMode"
                style="margin-right: 1rem"></esa-material-button>
            <esa-material-button
                *ngIf="canEdit()"
                icon="edit"
                type="primary"
                (click)="enableEditMode()"
                [matTooltip]="editMode ? '' : 'Edit Project'"
                [disabled]="editMode"></esa-material-button>
        </div>
    </div>

    <p>This page allows you to edit the project.</p>

    <ng-container *ngIf="project as p">
        <pto-project-form
            #updateProjectForm
            [projectID]="p.ProjectID"
            [project]="p"
            [editMode]="editMode"
            rowSelection="single"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></pto-project-form>

        <div class="meta-data">
            <h4>Project Meta Data</h4>
            <div class="md-copy">
                <p>Date Created: {{ p.CreateDate | date }}</p>
                <p>Created by: {{ p.CreateUser?.FullName }}</p>
                <p *ngIf="p.UpdateDate">Last Updated: {{ p.UpdateDate | date }}</p>
                <p *ngIf="p.UpdateUser">Updated by: {{ p.UpdateUser?.FullName }}</p>
            </div>
        </div>
    </ng-container>
</div>
