<h2 mat-dialog-title>System Info</h2>
<mat-dialog-content>
    <ng-container *ngIf="data?.systemInfo">
        <p>
            Version: {{ data.systemInfo?.Version }} - {{ data.systemInfo?.Environment }}&nbsp;&nbsp;|&nbsp;&nbsp;Built on:
            {{ data.systemInfo?.CompilationDateTime | date: "short" }}
        </p>
        <p>Copyright &copy; {{ currentYear }} State of California</p>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <esa-material-button mat-dialog-close label="Close" icon="cancel" type="clear"></esa-material-button>
</mat-dialog-actions>
