import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: "pto-no-click-edit-renderer",
    templateUrl: "./no-click-edit-renderer.component.html",
    styleUrls: ["./no-click-edit-renderer.component.scss"],
})
export class NoClickEditRendererComponent implements ICellRendererAngularComp {
    private params!: any;
    public editMode: any;
    public displayValue!: string;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.editMode = params.colDef.editable;
        this.displayValue = params.valueFormatted ?? params.value ?? "";
    }

    onClick() {
        this.params.api.startEditingCell({
            rowIndex: this.params.node.rowIndex,
            colKey: this.params.column.getId(),
        });
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
