export const environment = {
    production: false,
    staging: true,
    dev: false,
    mainAppApiUrl: "https://pto-api.esa-qa.sitkatech.com",
    createAccountRedirectUrl: "https://pto.esa-qa.sitkatech.com/create-user-callback",
    platformLongName: "Potential-to-Occur Database",
    appInsightsInstrumentationKey: "63a5602c-dde2-430f-907d-ef2679b9999b",
    datadogClientToken: "pub6c4a7a9a3a98b43093c95dc3361ecdba"
};
