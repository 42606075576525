import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CustomPageService } from "src/app/shared/generated/api/custom-page.service";
import { MenuItemService } from "src/app/shared/generated/api/menu-item.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { CustomPageDto } from "src/app/shared/generated/model/custom-page-dto";
import { CustomPageUpsertDto } from "src/app/shared/generated/model/custom-page-upsert-dto";
import { MenuItemDto } from "src/app/shared/generated/model/menu-item-dto";
import { RoleDto } from "src/app/shared/generated/model/role-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { Subscription, forkJoin } from "rxjs";

@Component({
    selector: "pto-custom-page-edit-properties",
    templateUrl: "./custom-page-edit-properties.component.html",
    styleUrls: ["./custom-page-edit-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPageEditPropertiesComponent implements OnInit, OnDestroy {
    public currentUser: UserDto;
    public isCreating: boolean;
    public customPage: CustomPageDto;
    public roles: Array<RoleDto>;
    public menuItems: Array<MenuItemDto>;
    public model: CustomPageUpsertDto;
    public originalModel: string;
    public isLoading: boolean = true;
    public isLoadingSubmit: boolean = false;

    customPageResponse: Subscription;
    creatingSubscription: Subscription;
    getDataSubscription: Subscription;
    userSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private customPageService: CustomPageService,
        private menuItemService: MenuItemService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        this.creatingSubscription = this.route.data.subscribe((result) => {
            this.isCreating = result.create;
            this.model = new CustomPageUpsertDto();
            this.model.ViewableRoleIDs = [];
            const vanityUrl = this.route.snapshot.paramMap.get("vanity-url");

            if (!this.isCreating && vanityUrl) {
                this.getDataSubscription = forkJoin({
                    customPage: this.customPageService.customPagesGetByUrlCustomPageVanityURLGet(vanityUrl),
                    pageRoles: this.customPageService.customPagesGetByUrlCustomPageVanityURLRolesGet(vanityUrl),
                    menuItems: this.menuItemService.menuItemsGet(),
                    roles: this.roleService.rolesGet(),
                }).subscribe(({ customPage, pageRoles, menuItems, roles }) => {
                    this.customPage = customPage;

                    this.model.CustomPageDisplayName = customPage.CustomPageDisplayName;
                    this.model.CustomPageVanityUrl = customPage.CustomPageVanityUrl;
                    this.model.CustomPageContent = customPage.CustomPageContent;
                    this.model.MenuItemID = customPage.MenuItem.MenuItemID;
                    this.model.ViewableRoleIDs = pageRoles.map((pageRole) => pageRole.RoleID).sort();

                    this.roles = roles.filter((role) => role.RoleID !== RoleEnum.Admin && role.RoleID !== RoleEnum.NoAccess);

                    this.menuItems = menuItems;

                    this.originalModel = JSON.stringify(this.model);
                    this.isLoading = false;
                    this.cdr.markForCheck();
                });
            } else {
                this.getDataSubscription = forkJoin({
                    menuItems: this.menuItemService.menuItemsGet(),
                    roles: this.roleService.rolesGet(),
                }).subscribe(({ menuItems, roles }) => {
                    // remove admin from role picker as admins default to viewable for all custom pages
                    // and remove disabled users as well since they should not have viewable rights by default
                    this.roles = roles.filter((role) => role.RoleID !== RoleEnum.Admin && role.RoleID !== RoleEnum.NoAccess);

                    this.menuItems = menuItems;

                    this.originalModel = JSON.stringify(this.model);
                    this.isLoading = false;
                    this.cdr.markForCheck();
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.creatingSubscription?.unsubscribe();
        this.getDataSubscription?.unsubscribe();
        this.customPageResponse?.unsubscribe();
        this.userSubscription?.unsubscribe();
    }

    canExit() {
        return this.originalModel === JSON.stringify(this.model);
    }

    slugifyPageName(event: any): void {
        const urlSlug = event
            ?.toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
        this.model.CustomPageVanityUrl = urlSlug;
    }

    validPageName(pageName: string): boolean {
        const pattern = /^[_A-Za-z0-9\-\s]{1,100}$/;
        return pattern.test(pageName);
    }

    validVanityUrl(vanityUrl: string): boolean {
        const pattern = /^[_A-Za-z0-9\-]{1,100}$/;
        return pattern.test(vanityUrl);
    }

    saveForm(): void {
        this.isLoadingSubmit = true;

        if (this.isCreating) {
            this.customPageResponse = this.customPageService.customPagesPost(this.model).subscribe(
                () => {
                    this.onSubmitSuccess();
                },
                (error) => {
                    this.onSubmitFailure();
                }
            );

            return;
        }

        this.customPageResponse = this.customPageService.customPagesCustomPageIDPut(this.customPage.CustomPageID, this.model).subscribe(
            () => {
                this.onSubmitSuccess();
            },
            (error) => {
                this.onSubmitFailure();
            }
        );
    }

    private onSubmitSuccess() {
        this.isLoadingSubmit = false;
        this.originalModel = JSON.stringify(this.model);

        this.router.navigateByUrl(`/custom-pages/${this.model.CustomPageVanityUrl}`).then(() => {
            this.alertService.pushAlert(new Alert(`The custom page was successfully ${this.isCreating ? "created" : "updated"}.`, AlertContext.Success));
        });
    }

    private onSubmitFailure() {
        this.isLoadingSubmit = false;
        this.cdr.detectChanges();
    }

    cancelEdit() {
        this.router.navigateByUrl("/manage/custom-pages");
    }
}
