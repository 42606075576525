import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NotFoundComponent } from "./pages";
import { HeaderNavComponent } from "./components";
import { UnauthenticatedComponent } from "./pages/unauthenticated/unauthenticated.component";
import { SubscriptionInsufficientComponent } from "./pages/subscription-insufficient/subscription-insufficient.component";
import { NgProgressModule } from "ngx-progressbar";
import { RouterModule } from "@angular/router";
import { LinkRendererComponent } from "./components/ag-grid/link-renderer/link-renderer.component";
import { FontAwesomeIconLinkRendererComponent } from "./components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component";
import { MultiLinkRendererComponent } from "./components/ag-grid/multi-link-renderer/multi-link-renderer.component";
import { CustomRichTextComponent } from "./components/custom-rich-text/custom-rich-text.component";
import { FieldDefinitionComponent } from "./components/field-definition/field-definition.component";
import { FieldDefinitionGridHeaderComponent } from "./components/field-definition-grid-header/field-definition-grid-header.component";
import { CustomPinnedRowRendererComponent } from "./components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { CustomDropdownFilterComponent } from "./components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CsvDownloadButtonComponent } from "./components/csv-download-button/csv-download-button.component";
import { ClearGridFiltersButtonComponent } from "./components/clear-grid-filters-button/clear-grid-filters-button.component";
import { ContextMenuRendererComponent } from "./components/ag-grid/context-menu/context-menu-renderer.component";
import { FooterNavComponent } from "./footer-nav/footer-nav.component";
import { DropdownToggleDirective } from "./directives/dropdown-toggle.directive";
import { PopperDirective } from "./directives/popper.directive";
import { PopperComponent } from "./components/popper/popper.component";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { ConfirmDialog } from "./components/confirm-dialog/confirm-dialog.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatChipsModule } from "@angular/material/chips";
import { CustomFormLabelComponent } from "./components/custom-form-label/custom-form-label.component";
import { TinymceEditorComponent } from "./components/tinymce-editor/tinymce-editor.component";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { MaterialChipsRendererComponent } from "./components/ag-grid/material-chips-renderer/material-chips-renderer.component";
import { DialogComponent } from "./components/dialog/dialog.component";
@NgModule({
    declarations: [
        HeaderNavComponent,
        NotFoundComponent,
        UnauthenticatedComponent,
        SubscriptionInsufficientComponent,
        LinkRendererComponent,
        FontAwesomeIconLinkRendererComponent,
        MultiLinkRendererComponent,
        CustomRichTextComponent,
        FieldDefinitionComponent,
        FieldDefinitionGridHeaderComponent,
        CustomPinnedRowRendererComponent,
        CustomDropdownFilterComponent,
        CsvDownloadButtonComponent,
        ClearGridFiltersButtonComponent,
        ContextMenuRendererComponent,
        FooterNavComponent,
        DropdownToggleDirective,
        PopperDirective,
        PopperComponent,
        ConfirmDialog,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        FileUploaderComponent,
        MaterialChipsRendererComponent,
        DialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgProgressModule,
        RouterModule,
        EditorModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        NgxMaterialTimepickerModule,
        MatMenuModule,
        EsaMaterialButtonComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        NotFoundComponent,
        HeaderNavComponent,
        CustomRichTextComponent,
        FieldDefinitionComponent,
        FieldDefinitionGridHeaderComponent,
        CsvDownloadButtonComponent,
        ClearGridFiltersButtonComponent,
        ContextMenuRendererComponent,
        FooterNavComponent,
        PopperDirective,
        EditorModule,
        ConfirmDialog,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        FileUploaderComponent,
        MaterialChipsRendererComponent,
        DialogComponent,
    ],
    providers: [
        {
            provide: TINYMCE_SCRIPT_SRC,
            useValue: "assets/tinymce/tinymce.min.js",
        },
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
