import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectSpeciesTableRowDto } from "src/app/shared/generated/model/project-species-table-row-dto";

@Component({
    selector: "pto-species-details-dialog",
    templateUrl: "./species-details-dialog.component.html",
    styleUrls: ["./species-details-dialog.component.scss"],
})
export class SpeciesDetailsDialogComponent implements OnInit {
    selectedSpecies: ProjectSpeciesTableRowDto;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit(): void {
      this.selectedSpecies = this.data.selectedSpecies.Species;
    }
}
