<form class="form grid-12 project-form" [formGroup]="projectForm">
    <div class="g-col-4" style="align-self: center">
        <esa-material-ff
            [formGroup]="projectForm"
            formControlName="ProjectName"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [customLabel]="projectNameCustomLabel"
            label="Project Name">
        </esa-material-ff>
        <ng-template #projectNameCustomLabel>
            <pto-custom-form-label label="Project Name" fieldDefinitionType="ProjectName" [required]="true"></pto-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-4" style="align-self: center">
        <esa-material-ff
            [formGroup]="projectForm"
            formControlName="ProjectNumber"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [customLabel]="projectNumberCustomLabel"
            label="Project Number">
        </esa-material-ff>
        <ng-template #projectNumberCustomLabel>
            <pto-custom-form-label label="Project Number" fieldDefinitionType="ProjectNumber" [required]="true"></pto-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-4" style="align-self: center">
        <div class="kv-pair">
            <pto-custom-form-label label="Tags" fieldDefinitionType="Tags"></pto-custom-form-label>
            <mat-form-field appearance="outline" class="value" *ngIf="editMode">
                <mat-chip-list #chipList aria-label="Tag selection">
                    <mat-chip *ngFor="let tag of selectedTags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
                        {{ tag.Name }}
                        <mat-icon matChipRemove *ngIf="tagIsRemovable">cancel</mat-icon>
                    </mat-chip>
                    <input
                        matInput
                        type="text"
                        #tagInput
                        [formControl]="tagControl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="false"
                        (matChipInputTokenEnd)="addTag($event)" />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                        {{ tag.Name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-hint style="margin-top: 1rem">Press enter after typing out a tag.</mat-hint>
            </mat-form-field>

            <div class="value-display middle-form-spacing tag-padding" *ngIf="!editMode && selectedTags.length <= 0">
                <span>-</span>
            </div>

            <div class="value-display middle-form-spacing-chips" *ngIf="!editMode && selectedTags.length > 0" style="padding: 1rem 0 1rem 0">
                <mat-chip-list #chipList aria-label="Tag selection">
                    <mat-chip *ngFor="let tag of selectedTags">
                        {{ tag.Name }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>

    <div class="g-col-12" *ngIf="createMode">
        <div class="grid-filter-bar flex-between">
            <div class="flex-start" style="width: 50%">
                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Search Species</mat-label>
                    <span matPrefix><mat-icon>search</mat-icon></span>
                    <input matInput type="text" [(ngModel)]="searchGridValue" (ngModelChange)="searchGrid()" [ngModelOptions]="{ standalone: true }" />
                    <button *ngIf="searchGridValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetGrid()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="flex-end">
                <div>
                    <mat-label style="margin-right: 1rem">Filter by:</mat-label>
                    <button mat-stroked-button color="primary" style="margin-right: 1rem" (click)="filterSpecies('Plant')" [disabled]="isActive">Plant</button>

                    <button mat-stroked-button color="primary" style="margin-right: 1rem" (click)="filterSpecies('Wildlife')" [disabled]="isActive">
                        Wildlife
                    </button>
                    <button
                        mat-mini-fab
                        color="primary"
                        aria-label="menu icon"
                        style="margin-right: 2rem"
                        [disabled]="!speciesGrid?.api?.isColumnFilterPresent()"
                        (click)="resetFilter()">
                        <mat-icon>restart_alt</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!createMode" class="g-col-6 flex-start" style="margin-top: 1rem">
        <!-- Leaving might to want introduce later - JS 2/1/24 -->
        <!-- <esa-material-button
            type="primary"
            label="Undo"
            icon="undo"
            (click)="speciesGrid.api.undoCellEditing()"
            style="margin-right: 1rem"
        >
        </esa-material-button>
        <esa-material-button
            type="primary"
            label="Redo"
            icon="redo"
            (click)="speciesGrid.api.redoCellEditing()"
            style="margin-right: 1rem"
        >
        </esa-material-button> -->
        <esa-material-button type="primary" label="Species" icon="add" (click)="addSpecies()" [disabled]="!editMode"> </esa-material-button>
    </div>

    <div *ngIf="!createMode" class="g-col-6 flex-end" style="margin-top: 1rem">
        <esa-material-button
            type="primary"
            label="Document"
            icon="file_download"
            (click)="generateDoc()"
            style="margin-left: 1rem"
            [disabled]="editMode"
            matTooltip="Download Word Document"
            style="margin-right: 1rem">
        </esa-material-button>
        <esa-material-button *ngIf="pinnedColumns" type="primary" label="Unpin Columns" icon="clear_all" (click)="clearPinned()"> </esa-material-button>
        <esa-material-button *ngIf="!pinnedColumns" type="primary" label="Pin Columns" icon="push_pin" (click)="pinColumns()"> </esa-material-button>
    </div>

    <div [ngClass]="createMode ? 'g-col-9' : 'g-col-12'">
        <div class="table-responsive">
            <ag-grid-angular
                #speciesGrid
                style="width: 100%; height: 100%"
                class="ag-theme-alpine"
                [rowData]="rowData"
                [columnDefs]="gridColumnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                [suppressClickEdit]="true"
                [animateRows]="true"
                [rowSelection]="rowSelection"
                [enableCellChangeFlash]="true"
                [enableBrowserTooltips]="true"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onProjectSpeciesGridReady($event)"
                [stopEditingWhenCellsLoseFocus]="true"
                [rowMultiSelectWithClick]="true"
                (rowSelected)="trackSelectedSpecies($event)">
            </ag-grid-angular>
        </div>
    </div>

    <div class="g-col-3 table-responsive" *ngIf="createMode">
        <mat-card class="table-responsive species-list">
            <mat-card-title>Selected Species</mat-card-title>
            <mat-card-content class="species-list-content">
                <ng-container *ngIf="selectedSpecies.length > 0; else noSpecies">
                    <mat-list role="list" *ngFor="let speices of selectedSpecies">
                        <mat-list-item role="listitem" class="species-list-item"
                            ><mat-icon mat-list-icon style="color: lightgray">check_small</mat-icon> {{ speices.SpeciesCommonName }}</mat-list-item
                        >
                        <mat-divider></mat-divider>
                    </mat-list>
                </ng-container>
                <ng-template #noSpecies>
                    <mat-list role="list">
                        <mat-list-item role="listitem" class="species-list-item">No species are selected.</mat-list-item>
                    </mat-list>
                </ng-template>
            </mat-card-content>
            <mat-card-actions align="end">
                <mat-card-subtitle>Total: {{ selectedSpecies.length }}</mat-card-subtitle>
            </mat-card-actions>
        </mat-card>
    </div>

    <div class="g-col-12 actions-bar" *ngIf="editMode">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Save"
            icon="save"
            [tooltip]="!projectForm.valid ? 'You are missing required fields.' : ''"
            (click)="saveForm()"
            [disabled]="!projectForm.valid">
        </esa-material-button>

        <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
    </div>
</form>
