<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div *ngIf="!isLoading">
    <ng-container *ngIf="!isEditing">
        <div *ngIf="showEditButton()" class="customRichTextContent" style="position: relative; min-height: 60px">
            <div class="hoverEditButton">
                <div style="transform: translate3d(-10px, 20px, 0)">
                    <esa-material-button type="clear" icon="edit" (click)="enterEdit()"></esa-material-button>
                </div>
            </div>
            <div *ngIf="!emptyContent" [innerHtml]="customRichTextContent"></div>

            <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
        </div>
        <div *ngIf="!showEditButton()" [innerHtml]="customRichTextContent"></div>
    </ng-container>

    <div *ngIf="isEditing">
        <editor #tinyMceEditor [init]="tinyMceConfig" [(ngModel)]="editedContent"></editor>

        <div style="float: right; padding-top: 0.5rem">
            <esa-material-button type="primary" label="Save" (click)="saveEdit()" icon="save" style="margin-right: 0.5rem"></esa-material-button>
            <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEdit()"></esa-material-button>
        </div>
    </div>
</div>
