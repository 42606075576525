import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: "system-version-dialog",
    templateUrl: "./system-version-dialog.component.html",
    styleUrls: ["./system-version-dialog.component.scss"],
})
export class SystemVersionDialogComponent implements OnInit {
    public currentYear: number = new Date().getFullYear();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit(): void {}
}
