import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { ProjectSpeciesTableRowDto } from "src/app/shared/generated/model/project-species-table-row-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
    selector: "pto-project-copy",
    templateUrl: "./project-copy.component.html",
    styleUrls: ["./project-copy.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCopyComponent implements OnInit {
    sourceProject$: Observable<ProjectDto>;
    project: ProjectDto;

    constructor(
        private projectService: ProjectService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        const projectToCopyId = this.route.snapshot.paramMap.get("projectToCopyId");

        this.sourceProject$ = this.projectService.projectsProjectIDGet(parseInt(projectToCopyId, 10)).pipe(
            map((projectToCopy) => {
                const copiedProjectSpeciesTableRow = projectToCopy.ProjectSpeciesTableRows.map((row) => {
                    return new ProjectSpeciesTableRowDto({
                        Species: row.Species,
                    });
                });

                this.project = new ProjectDto({
                    Tags: projectToCopy.Tags,
                    ProjectSpeciesTableRows: copiedProjectSpeciesTableRow,
                });

                this.cdr.markForCheck();
                this.alertService.pushAlert(new Alert(`Successfully copied from project ${projectToCopy.ProjectName}.`, AlertContext.Success), 5000);

                return projectToCopy;
            })
        );

        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    save(result: any) {
        if (!!result) this.router.navigate([`projects/${result.ProjectID}`]);
    }

    cancelEdit() {
        this.router.navigate([`projects`]);
    }
}
