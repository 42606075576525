import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
@Component({
    selector: "pto-material-chips-renderer",
    templateUrl: "./material-chips-renderer.component.html",
    styleUrls: ["./material-chips-renderer.component.scss"],
})
export class MaterialChipsRendererComponent implements AgRendererComponent {
    tags: any[];

    agInit(params: any): void {
        if (params.Tags) {
            this.tags = params.Tags;
            this.tags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name));
        } else {
            this.tags = [];
        }
    }

    refresh(params: any): boolean {
        return false;
    }
}
