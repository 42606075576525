import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CurrentProjectService } from "src/app/services/current-project.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";

@Component({
    selector: "pto-project-detail",
    templateUrl: "./project-detail.component.html",
    styleUrls: ["./project-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailComponent implements OnInit {

    constructor(
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.currentProjectService.setCurrentProject(null);

            this.projectService.projectsProjectIDGet(parseInt(params.id, 10)).subscribe(
                (data) => {
                    this.currentProjectService.setCurrentProject(data);
                },
                () => {
                    this.router.navigate([""]);
                }
            );
        });

    }

    ngOnDestroy(): void {
        this.currentProjectService.setCurrentProject(null);
    }
}
