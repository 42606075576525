<div class="detail-view">
    <div class="edit-definition" *ngIf="fieldDefinition$ | async as fieldDefinition">
        <div class="page-header pb-2">
            <h1 class="page-title">Edit Definition for Label: {{ fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName }}</h1>
        </div>

        <editor
            #tinyMceEditor
            [init]="{ toolbar: false, menubar: false, plugins: 'quickbars', promotion: false }"
            [(ngModel)]="fieldDefinition.FieldDefinitionValue"></editor>

        <div class="actions-bar" style="margin-top: 1rem">
            <esa-material-button
                type="primary"
                label="Save"
                icon="save"
                (click)="saveDefinition()"
                [disabled]="isLoadingSubmit"
                style="margin-right: 1rem"></esa-material-button>
            <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
        </div>
    </div>
</div>
