import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentProjectService } from "src/app/services/current-project.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { ProjectUpsertDto } from "src/app/shared/generated/model/project-upsert-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { ProjectFormComponent } from "../../shared/project-form/project-form.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { ConfirmDialog } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { Router } from "@angular/router";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";

@Component({
    selector: "pto-project-detail-summary",
    templateUrl: "./project-detail-summary.component.html",
    styleUrls: ["./project-detail-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailSummaryComponent implements OnInit, OnDestroy {
    @ViewChild("updateProjectForm", { read: ProjectFormComponent })
    form: ProjectFormComponent;

    public project: ProjectDto;
    public currentUser: UserDto;
    public editMode = false;
    public projectUpsertDto: ProjectUpsertDto;

    currentProjectSub: Subscription;
    user: Subscription;

    constructor(
        private currentProjectService: CurrentProjectService,
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.currentProjectSub = this.currentProjectService.getCurrentProject().subscribe((data) => {
            if (data) {
                this.project = data;
                this.projectUpsertDto = this.currentProjectService.createProjectDto(data);
                this.cdr.markForCheck();
            }
        });

        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.currentProjectSub?.unsubscribe();
    }

    refreshProject(result: any) {
        this.editMode = false;
        this.currentProjectService.setCurrentProject(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result: any) {
        this.refreshProject(result);
    }

    canEdit(): boolean {
        return this.currentProjectService.canEditCurrentProject(this?.currentUser, this.project);
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(false);
        this.cdr.detectChanges();
    }

    canExit() {
        if (this.editMode) {
            return (
                JSON.stringify(this.projectUpsertDto) === JSON.stringify(this.form.projectUpsertDto) &&
                JSON.stringify(this.project.ProjectSpeciesTableRows) === JSON.stringify(this.form.rowData)
            );
        } else {
            return true;
        }
    }

    hasReadOnly() {
        const read = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.ProjectRights, RightsEnum.Read);
        const update = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.ProjectRights, RightsEnum.Update);
        const remove = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
        const create = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
        if (read && !update && !remove && !create) {
            return true;
        } else if (read && this?.currentUser?.UserID != this.project?.CreateUser?.UserID && !this.authenticationService.hasFlag(this.currentUser, FlagEnum.CanEditAllProjects)) {
            return true;
        } else {
            return false;
        }
    }

    canDelete() {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
    }

    public delete() {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete Project",
                text: `You are about to delete project ${this.project.ProjectName}. This action cannot be undone. Are you sure you wish to proceed?`,
            },
            width: "500px",
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.projectService.projectsProjectIDDelete(this.project.ProjectID).subscribe(
                    () => {
                        this.alertService.pushAlert(new Alert(`Project was successfully deleted.`, AlertContext.Success, true));
                        this.router.navigate(["/projects"]);
                    },
                    (error) => {
                        this.alertService.pushAlert(new Alert(`There was an error deleting the project. Please try again.`, AlertContext.Danger, true));
                    }
                );
            }
        });
    }
}
