<div class="home-splash">
    <div class="photo">
        <img src="../assets/main/pto.jpg" alt="Pto" />
    </div>

    <div class="info">
        <div *ngIf="userRoleIsDisabled()">
            <span>
                Your account is currently disabled. Please
                <a routerLink="/help">Request Support</a> if you would like to activate your account.
            </span>
        </div>

        <div *ngIf="userIsUnassigned()"   class="copy copy-2">
            <h3>Account Configuration Required</h3>

            <p>Thank you for creating an account with ESA's Potential to Occur (PTO) Application!</p>
            <p>Your account is pending configuration by our administrators. Please check back in 24 hours to access and start using the application. We apologize for any inconvenience and appreciate your understanding.</p>
            <p>If you have any urgent inquiries or need to access the app sooner, please contact one of our Bio Leads:</p>
            <ul>
                <li>Daniel Huang, <a href="mailto:DHuang@esassoc.com">DHuang@esassoc.com</a></li>
                <li>Tierra Groff, <a href="mailto:TGroff@esassoc.com">TGroff@esassoc.com</a></li>
                <li>Brian Rawles, <a href="mailto:BRawles@esassoc.com">BRawles@esassoc.com</a></li>
            </ul>

            <p>Thank you!</p>
        </div>

        <div *ngIf="!userIsUnassigned()"  class="copy copy-2" style="padding-bottom: 3rem">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>

        <div class="card mt-1">
            <div class="card-header">
                <h3 class="card-title">
                    <ng-container *ngIf="!currentUser">Access</ng-container>
                    <ng-container *ngIf="currentUser">Quick actions</ng-container>
                </h3>
            </div>
            <div class="card-body" *ngIf="!currentUser">
                <div class="copy copy-3">
                    <p>Sign in to view your Account. Create an Account if you don't have one yet.</p>
                </div>

                <div class="mt-2">
                    <esa-material-button type="primary" label="Sign In" aria-label="Sign In" (click)="login()"></esa-material-button>
                    <esa-material-button
                        type="primary"
                        label="Create Account"
                        aria-label="Create Account"
                        (click)="login()"
                        style="margin-left: 1rem"></esa-material-button>
                </div>
            </div>

            <div class="card-body" *ngIf="currentUser">
                <esa-material-button type="primary" label="Request Support" (click)="requestSupport()"></esa-material-button>
            </div>
        </div>
    </div>
</div>
