import { NgComponentOutlet } from "@angular/common";
import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "pto-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements AfterViewInit {
    @ViewChild(NgComponentOutlet, { static: true }) ngComponentOutlet!: NgComponentOutlet;

    childFormIsValid: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngAfterViewInit(): void {
        this.ngComponentOutlet["_componentRef"].instance.onFormVaild.subscribe((event: any) => {
            this.childFormIsValid = event;
        });
    }
}
