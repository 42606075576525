import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    selector: "pto-species-display",
    templateUrl: "./species-display.component.html",
    styleUrls: ["./species-display.component.scss"],
})
export class SpeciesDisplayComponent implements AgRendererComponent {
    params: any;

  agInit(params: any): void {
        if (params.value === null) {
            params = {
                value: {
                    SpeciesCommonName: "",
                    SpeciesScientificName: "",
                },
            };
        } else {
            this.params = params;
        }
    }

    refresh(params: any): boolean {
        return false;
    }
}
