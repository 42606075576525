<div class="list-view">
    <div class="page-header">
        <h1 class="page-title">New Project</h1>
    </div>
    <p>This page allows you to create a new Project PTO table.</p>

    <pto-project-form
        #createNewProjectForm
        [project]="project"
        [editMode]="true"
        [createMode]="true"
        rowSelection="multiple"
        (formSubmitted)="save($event)"
        (cancelEditModeChange)="cancelEdit()"></pto-project-form>
</div>
