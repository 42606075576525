/**
 * Pto.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SpeciesDto } from '././species-dto';
import { LikelihoodDto } from '././likelihood-dto';

export class ProjectSpeciesTableRowDto { 
    ProjectSpeciesTableRowID?: number;
    ProjectID?: number;
    Species?: SpeciesDto;
    Likelihood?: LikelihoodDto;
    Description?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
