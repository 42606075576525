import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CurrentProjectService } from "src/app/services/current-project.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { ProjectUpsertDto } from "src/app/shared/generated/model/project-upsert-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { VProjectTagsDto } from "src/app/shared/generated/model/v-project-tags-dto";
import { ColDef } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { DialogComponent } from "src/app/shared/components/dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AddProjectSpeciesFormComponent } from "../project-species-form/add-project-species-form.component";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { SpeciesService } from "src/app/shared/generated/api/species.service";
import { ProjectSpeciesTableRowUpsertDto } from "src/app/shared/generated/model/project-species-table-row-upsert-dto";
import { ProjectSpeciesTableRowDto } from "src/app/shared/generated/model/project-species-table-row-dto";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { LikelihoodDto } from "src/app/shared/generated/model/likelihood-dto";
import { GenerateWordDocService } from "src/app/shared/services/generate-word-doc.service";
import { LikelihoodService } from "src/app/shared/generated/api/likelihood.service";
import { SpeciesDto } from "src/app/shared/generated/model/species-dto";
import { NoClickEditRendererComponent } from "src/app/shared/components/ag-grid/no-click-edit-renderer/no-click-edit-renderer.component";
import { SpeciesDisplayComponent } from "src/app/shared/components/ag-grid/species-display/species-display.component";
import { SpeciesDetailsDialogComponent } from "../species-details-dialog/species-details-dialog.component";

export abstract class Species {
    constructor(public species: any) {}
}

export class ProjectSpeciesIDs extends Species {}
@Component({
    selector: "pto-project-form",
    templateUrl: "./project-form.component.html",
    styleUrls: ["./project-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFormComponent implements OnInit, OnDestroy {
    @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;
    @ViewChild("speciesGrid") speciesGrid: AgGridAngular;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() projectID: number;
    @Input() project: any;
    @Input() editMode: boolean;
    @Input() isCopyMode: boolean = false;
    @Input() createMode: boolean = false;
    @Input() rowSelection: string;

    projectForm: FormGroup;

    projectUpsertDto: ProjectUpsertDto;
    allTags: VProjectTagsDto[];
    likeihoodList: LikelihoodDto[];

    rowData: any[] = [];
    defaultColDef: any;
    gridColumnDefs: ColDef[];
    searchGridValue: string;
    isActive: boolean = false;
    pinnedColumns: boolean = false;
    frameworkComponents: any;
    selectedSpecies: SpeciesDto[] = [];

    selectedTags: any[] = [];
    filteredTags: Observable<any[]>;
    tagControl = new FormControl();
    tagIsRemovable = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    speciesSub: Subscription;
    projectSub: Subscription;
    likelihoodSub: Subscription;
    injector: Injector;

    constructor(
        private currentProjectService: CurrentProjectService,
        private projectService: ProjectService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        private speciesService: SpeciesService,
        private editViewEventService: EditViewEventService,
        private inj: Injector,
        private wordDocService: GenerateWordDocService,
        private likelihoodService: LikelihoodService,
        private formBuilder: FormBuilder
    ) {
        this.frameworkComponents = {
            buttonRenderer: ButtonRendererComponent,
            noClickEditRenderer: NoClickEditRendererComponent,
        };
    }

    ngOnInit(): void {
        this.setForm();

        this.projectService.projectTagsGet().subscribe((response) => {
            this.allTags = response;
            this.filteredTags = this.tagControl.valueChanges.pipe(
                startWith(null),
                map((tagName: string | null) => (tagName ? this._filter(tagName) : this.allTags?.slice()))
            );
        });

        this.editViewEventService.listenForEditButtonClicked().subscribe((response) => {
            if (response) {
                this.gridColumnDefs = this.toggleColumns(true);
                this.defaultColDef.editable = true;
                this.cdr.markForCheck();
            } else {
                this.gridColumnDefs = this.toggleColumns(false);
                this.defaultColDef.editable = false;
                this.cdr.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this.speciesGrid?.api?.destroy();
        this.cdr.markForCheck();
        this.speciesSub?.unsubscribe();
        this.projectSub?.unsubscribe();
        this.likelihoodSub?.unsubscribe();
        this.cdr?.detach();
    }

    setProjectRowData() {
        return this.project.ProjectSpeciesTableRows.map((x) => {
            if (x.Likelihood) return x;
            return {
                ...x,
                Likelihood: new LikelihoodDto(),
            };
        });
    }

    // Grid Action Methods
    onProjectSpeciesGridReady(gridEvent: any) {
        this.speciesGrid.api.showLoadingOverlay();

        if (this.isCopyMode) {
            this.likelihoodSub = this.likelihoodService.likelihoodsGet().subscribe((resp) => {
                this.likeihoodList = resp;
                this.cdr.markForCheck();
                this.buildEditableColumns();
                this.gridColumnDefs = this.toggleColumns(true);
                this.pinnedColumns = true;
                this.rowData = this.setProjectRowData();
                this.speciesGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
        } else if (this.projectID) {
            this.likelihoodSub = this.likelihoodService.likelihoodsGet().subscribe((resp) => {
                this.likeihoodList = resp;
                this.cdr.markForCheck();
                this.buildEditableColumns();
                this.rowData = this.setProjectRowData();
                this.speciesGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
        } else {
            this.speciesSub = this.speciesService.speciesGet().subscribe((resp) => {
                this.buildCreateColumns();
                this.rowData = resp;
                this.speciesGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
        }
    }

    buildEditableColumns() {
        this.defaultColDef = {
            sortable: false,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
            editable: false,
        };

        const arrayToObject = (array: any) =>
            array.reduce((obj: any, item: any) => {
                obj[item.LikelihoodID] = item.LikelihoodDisplayName;
                return obj;
            }, {});

        const likelihoodRefObj = arrayToObject(this.likeihoodList);
        const extractKeys = (mappings: any) => Object.keys(mappings);
        const likeihoodCodes = extractKeys(likelihoodRefObj);
        const convertedCodes = likeihoodCodes.map((code) => parseInt(code, 10));

        function lookupValue(mappings: Record<string, string>, key: string) {
            return mappings[key];
        }

        let component = this;

        this.gridColumnDefs = [
            {
                headerName: "Delete",
                headerTooltip: "Delete",
                field: "Delete",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: function (params: any) {
                        component.deleteRow(params);
                    },
                    icon: "delete",
                },
                cellStyle: () => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                editable: false,
                width: 100,
                hide: !this.isCopyMode,
                autoHeight: true,
            },
            {
                headerName: "Details",
                headerTooltip: "View Species Details",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: function (params: any) {
                        component.viewSpeciesDetails(params);
                    },
                    icon: "open_in_new",
                },
                cellStyle: () => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                editable: false,
                width: 100,
                hide: !this.isCopyMode,
                autoHeight: true,
            },
            {
                headerName: "Common Name (Scientific Name)",
                headerTooltip: "Common Name (Scientific Name)",
                field: "Species.SpeciesCommonName",
                minWidth: 300,
                editable: false,
                valueGetter: function (params: any) {
                    return {
                        SpeciesCommonName: params.data.Species.SpeciesCommonName,
                        SpeciesScientificName: params.data.Species.SpeciesScientificName,
                    };
                },
                cellRendererFramework: SpeciesDisplayComponent,
                filterValueGetter: function (params: any) {
                    return params.data.Species.SpeciesCommonName + " " + params.data.Species.SpeciesScientificName;
                },
                autoHeight: true,
            },
            {
                headerName: "Habitat Description",
                headerTooltip: "Habitat Description",
                field: "Species.HabitatDescription",
                tooltipField: "Species.HabitatDescription",
                minWidth: 300,
                flex: 1,
                editable: false,
                hide: !this.editMode,
                wrapText: true,
                autoHeight: true,
                valueGetter: (p) => p.data.Species.HabitatDescription,
                cellStyle: { "line-height": "22px" },
            },
            {
                headerName: "Micro Habitat",
                headerTooltip: "Micro Habitat",
                field: "Species.MicroHab",
                tooltipField: "Species.MicroHab",
                minWidth: 300,
                editable: false,
                hide: !this.editMode,
                flex: 1,
                wrapText: true,
                autoHeight: true,
                valueGetter: (p) => p.data.Species.MicroHab,
                cellStyle: { "line-height": "22px" },
            },
            {
                headerName: "Potential to Occur",
                headerTooltip: "Potential to Occur",
                field: "Likelihood.LikelihoodID",
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {
                    values: convertedCodes,
                },
                cellRenderer: "noClickEditRenderer",
                valueFormatter: (params) => {
                    return lookupValue(likelihoodRefObj, params.value);
                },
                filterValueGetter: function (params: any) {
                    return params.data.Likelihood.LikelihoodDisplayName;
                },
                minWidth: 200,
                editable: false,
            },
            {
                headerName: "PTO Determination",
                headerTooltip: "PTO Determination",
                field: "Description",
                cellEditorPopup: true,
                cellEditor: "agLargeTextCellEditor",
                cellEditorParams: {
                    rows: 10,
                    cols: 65,
                    maxLength: 700,
                },
                cellRenderer: "noClickEditRenderer",
                minWidth: 400,
                editable: false,
                flex: 1,
                wrapText: true,
                autoHeight: true,
                cellStyle: { "line-height": "22px" },
            },
        ];
    }

    buildCreateColumns() {
        this.defaultColDef = {
            sortable: false,
            filter: true,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
            editable: false,
        };

        this.gridColumnDefs = [
            {
                headerName: "Common Name (Scientific Name)",
                headerTooltip: "Common Name (Scientific Name)",
                field: "SpeciesCommonName",
                minWidth: 300,
                flex: 1,
                valueGetter: function (params: any) {
                    return {
                        SpeciesCommonName: params.data.SpeciesCommonName,
                        SpeciesScientificName: params.data.SpeciesScientificName,
                    };
                },
                filterValueGetter: function (params: any) {
                    return params.data.SpeciesCommonName + " " + params.data.SpeciesScientificName;
                },
                cellRendererFramework: SpeciesDisplayComponent,
                autoHeight: true,
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                headerName: "Habitat Description",
                headerTooltip: "Habitat Description",
                field: "HabitatDescription",
                tooltipField: "Habitat Description",
                minWidth: 300,
                flex: 2,
                wrapText: true,
                autoHeight: true,
                cellStyle: { "line-height": "22px" },
            },
            {
                field: "OrganismType.OrganismTypeDisplayName",
                hide: true,
            },
        ];
    }

    searchGrid() {
        this.speciesGrid.api.setQuickFilter(this.searchGridValue);
        this.cdr.markForCheck();
    }

    resetFilter() {
        this.isActive = false;
        this.speciesGrid?.api?.setFilterModel(null);
        this.cdr.markForCheck();
    }

    resetGrid() {
        this.speciesGrid.api.setQuickFilter("");
        this.searchGridValue = "";
        this.cdr.markForCheck();
    }

    toggleColumns(value: boolean) {
        const columnsToCheck = ["Delete", "Habitat Description", "Micro Habitat", "Details"];
        return this.gridColumnDefs.map((column) => {
            if (columnsToCheck.includes(column.headerName)) {
                return {
                    ...column,
                    hide: !value,
                };
            }

            if (column.headerName === "Potential to Occur" || column.headerName === "PTO Determination") {
                if (!value) {
                    return {
                        ...column,
                        editable: value,
                        pinned: null,
                    };
                } else {
                    return {
                        ...column,
                        editable: value,
                        pinned: null,
                    };
                }
            }

            return column;
        });
    }

    pinColumns() {
        this.speciesGrid.columnApi.applyColumnState({
            state: [
                {
                    colId: "Likelihood.LikelihoodID",
                    pinned: "right",
                },
                {
                    colId: "Description",
                    pinned: "right",
                },
            ],
            defaultState: { pinned: null },
        });
        this.pinnedColumns = true;
        this.cdr.detectChanges();
    }

    clearPinned() {
        this.speciesGrid.columnApi.applyColumnState({
            defaultState: { pinned: null },
        });
        this.pinnedColumns = false;
        this.cdr.detectChanges();
    }

    filterSpecies(type: string) {
        this.isActive = true;
        let organismFilterComponent = this.speciesGrid.api.getFilterInstance("OrganismType.OrganismTypeDisplayName");

        organismFilterComponent.setModel({
            type: "equals",
            filter: type,
        });
        this.speciesGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }

    trackSelectedSpecies(gridEvent: any) {
        const foundSpecies = this.selectedSpecies.find((species) => species.SpeciesID === gridEvent.data.SpeciesID);

        if (foundSpecies) {
            const updatedList = this.selectedSpecies.filter((species) => species.SpeciesID !== gridEvent.data.SpeciesID);
            this.selectedSpecies = updatedList;
            this.cdr.markForCheck();
        } else {
            this.selectedSpecies.push(gridEvent.data);
            this.cdr.markForCheck();
        }
    }

    // Form Action Methods
    addSpecies() {
        this.injector = Injector.create(
            [
                {
                    provide: ProjectSpeciesIDs,
                    useValue: this.rowData.map((x) => x.Species.SpeciesID),
                },
            ],
            this.inj
        );

        const dialogRef = this.dialog.open(DialogComponent, {
            data: {
                header: "Add Species",
                component: AddProjectSpeciesFormComponent,
                data: this.injector,
            },
            width: "50vw",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) return;

            const componentInstance = result["_componentRef"].instance;
            const speciesToAdd = componentInstance.selectedSpecies;

            if (!speciesToAdd) return;

            const newRow = new ProjectSpeciesTableRowDto({
                ProjectID: this.projectID,
                Species: speciesToAdd,
                Likelihood: new LikelihoodDto(),
            });

            this.rowData.push(newRow);
            this.speciesGrid.api.applyTransaction({
                add: [newRow],
                addIndex: 0,
            });
            this.cdr.markForCheck();
        });
    }

    public viewSpeciesDetails(params: any) {
        this.dialog.open(SpeciesDetailsDialogComponent, {
            data: {
                selectedSpecies: params.rowData,
            },
            width: "50vw",
        });
    }

    public deleteRow(params: any) {
        const keyToRemove = params.rowData.Species.SpeciesID;
        const idxToRemove = this.rowData.findIndex((tt) => {
            return tt.Species.SpeciesID === keyToRemove;
        });
        this.rowData.splice(idxToRemove, 1);
        this.speciesGrid.api.applyTransaction({ remove: [params.rowData] });

        if (params.rowData.ProjectSpeciesTableRowID) {
            this.projectUpsertDto.ProjectSpeciesToDelete.push(
                new ProjectSpeciesTableRowUpsertDto({
                    ProjectSpeciesTableRowID: params.rowData.ProjectSpeciesTableRowID,
                    SpeciesID: params.rowData.Species.SpeciesID,
                    LikelihoodID: params.rowData?.Likelihood?.LikelihoodID,
                    Description: params.rowData?.Description,
                })
            );
        }

        this.cdr.markForCheck();
    }

    setForm() {
        if (this.projectID) {
            this.projectForm = this.formBuilder.group({
                ProjectName: [this.project.ProjectName, Validators.required],
                ProjectNumber: [this.project.ProjectNumber, Validators.required],
            });
        } else {
            this.projectForm = this.formBuilder.group({
                ProjectName: ["", Validators.required],
                ProjectNumber: ["", Validators.required],
            });
        }

        this.projectUpsertDto = this.currentProjectService.createProjectDto(this.project);

        if (this.projectUpsertDto?.Tags) {
            this.selectedTags = JSON.parse(this.projectUpsertDto.Tags) || [];
        } else {
            this.selectedTags = [];
        }

        this.cdr.markForCheck();
    }

    saveForm() {
        this.speciesGrid.api.stopEditing();
        this.projectUpsertDto.ProjectName = this.projectForm.controls.ProjectName.value;
        this.projectUpsertDto.ProjectNumber = this.projectForm.controls.ProjectNumber.value;

        if (this.projectID) {
            this.rowData.map((x) => {
                if (x.ProjectSpeciesTableRowID) {
                    this.projectUpsertDto.ProjectSpeciesToUpdate.push(
                        new ProjectSpeciesTableRowUpsertDto({
                            ProjectSpeciesTableRowID: x.ProjectSpeciesTableRowID,
                            SpeciesID: x.Species.SpeciesID,
                            LikelihoodID: x.Likelihood?.LikelihoodID,
                            Description: x.Description,
                        })
                    );
                } else {
                    this.projectUpsertDto.ProjectSpeciesToAdd.push(
                        new ProjectSpeciesTableRowUpsertDto({
                            SpeciesID: x.Species.SpeciesID,
                            LikelihoodID: x.Likelihood?.LikelihoodID,
                            Description: x.Description,
                        })
                    );
                }
            });

            this.projectSub = this.projectService.projectsProjectIDPut(this.projectID, this.projectUpsertDto).subscribe((result) => {
                const updatedResult = {
                    ...result,
                    ProjectSpeciesTableRows: result.ProjectSpeciesTableRows.map((x) => {
                        if (x.Likelihood) return x;
                        return {
                            ...x,
                            Likelihood: new LikelihoodDto(),
                        };
                    }),
                };

                this.alertService.pushAlert(new Alert("The project was successfully updated.", AlertContext.Success), 5000);
                this.projectUpsertDto = this.currentProjectService.createProjectDto(updatedResult);
                this.rowData = updatedResult.ProjectSpeciesTableRows;
                this.cdr.markForCheck();
                this.speciesGrid.api.setRowData(updatedResult.ProjectSpeciesTableRows);
                this.speciesGrid.api.refreshCells({ force: true });
                this.formSubmitted.emit(updatedResult);
            });
        } else {
            if (!this.isCopyMode) {
                const selectedRows = this.speciesGrid.api.getSelectedRows();
                this.projectUpsertDto.ProjectSpeciesToAdd = selectedRows.map((row) => {
                    return new ProjectSpeciesTableRowUpsertDto({
                        SpeciesID: row.SpeciesID,
                    });
                });
            } else {
                this.projectUpsertDto.ProjectSpeciesToAdd = this.rowData.map((row) => {
                    return new ProjectSpeciesTableRowUpsertDto({
                        SpeciesID: row.Species.SpeciesID,
                        LikelihoodID: row.Likelihood.LikelihoodID,
                        Description: row.Description,
                    });
                });
            }

            this.projectSub = this.projectService.projectsPost(this.projectUpsertDto).subscribe(
                (result) => {
                    this.formSubmitted.emit(result);
                    this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
                },
                () => {
                    this.formSubmitted.emit(null);
                }
            );
        }
    }

    cancelEditMode() {
        this.setForm();
        if (this.projectID) {
            const rowData = this.setProjectRowData();
            this.speciesGrid.api.setRowData(rowData);
        }
        this.cancelEditModeChange.emit(true);
    }

    // Word Document Method
    generateDoc() {
        this.wordDocService.wordDocument(this.rowData);
        this.cdr.markForCheck();
    }

    // Tag Control Methods
    addTag(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();

        if (value) {
            this.selectedTags.push({ Name: value });
        }

        event.chipInput!.clear();
        this.tagControl.setValue(null);
        this.projectUpsertDto.Tags = JSON.stringify(this.selectedTags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name)));
        this.cdr.detectChanges();
    }

    removeTag(projectTag: any): void {
        const index = this.selectedTags.indexOf(projectTag);

        if (index >= 0) {
            this.selectedTags.splice(index, 1);
        }

        this.projectUpsertDto.Tags = JSON.stringify(this.selectedTags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name)));
        this.cdr.detectChanges();
    }

    selectTag(event: MatAutocompleteSelectedEvent): void {
        this.selectedTags.push({ Name: event.option.viewValue });
        this.tagInput.nativeElement.value = "";
        this.tagControl.setValue(null);
        this.projectUpsertDto.Tags = JSON.stringify(this.selectedTags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name)));
        this.cdr.detectChanges();
    }

    private _filter(value: string): any[] {
        const filterValue = value.hasOwnProperty("Name") ? (value as any).Name.toLowerCase() : value.toLowerCase();
        return this.allTags.filter((tag) => tag.Name.toLowerCase().includes(filterValue));
    }
}
