import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FileService } from "../../services/file/file.service";
import { Subscription } from "rxjs";

@Component({
    selector: "pto-file-uploader",
    templateUrl: "./file-uploader.component.html",
    styleUrls: ["./file-uploader.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnDestroy {
    @Input() fileRoute: string;
    @Output() hasFilesToUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadStarted: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadSuccess: EventEmitter<any> = new EventEmitter<any>();

    public filesToUpload: File[] = [];
    public isLoading: boolean = false;

    uploadSubscription: Subscription;

    constructor(
        private fileService: FileService,
        private cdr: ChangeDetectorRef
    ) {}

    onFilesSelected(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.filesToUpload.push(files.item(i));
        }

        if (this.filesToUpload.length) {
            this.hasFilesToUpload.emit(true);
        }
    }

    spliceFiles(index) {
        this.filesToUpload.splice(index, 1);

        if (!this.filesToUpload.length) {
            this.hasFilesToUpload.emit(false);
        }
    }

    uploadFiles() {
        this.isLoading = true;
        this.fileUploadStarted.emit(true);
        this.uploadSubscription = this.fileService.uploadFiles(this.fileRoute, this.filesToUpload).subscribe({
            next: (result) => {
                this.fileUploadSuccess.emit(true);
            },
            error: (err) => {
                this.fileUploadSuccess.emit(false);
            },
            complete: () => {
                this.filesToUpload = [];
                this.hasFilesToUpload.emit(false);
                this.isLoading = false;
                this.cdr.markForCheck();
            },
        });
    }

    ngOnDestroy(): void {
        this.uploadSubscription?.unsubscribe();
    }
}
