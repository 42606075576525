//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[FieldDefinitionType]

export enum FieldDefinitionTypeEnum {
  Name = 1,
  ProjectName = 2,
  ProjectNumber = 3,
  Tags = 4
}
