import { Injectable } from "@angular/core";
import { ApplicationInsights, ITelemetryItem, IDependencyTelemetry } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class AppInsightsService {
    constructor() {}

    initAppInsights() {
        const appInsights = new ApplicationInsights({
            config: {
                appId: "Pto",
                enableAutoRouteTracking: true,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                correlationHeaderExcludedDomains: [],
                instrumentationKey: environment.appInsightsInstrumentationKey,
                maxAjaxCallsPerView: -1,
            },
        });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
            if (item && item.baseData && [0, 401].indexOf((item.baseData as IDependencyTelemetry).responseCode) >= 0) {
                return false;
            }
        });

        (window as any).appInsights = appInsights;
    }
}
