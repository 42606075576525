import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SpeciesService } from "src/app/shared/generated/api/species.service";
import { SpeciesDto } from "src/app/shared/generated/model/species-dto";
import { ProjectSpeciesIDs } from "../project-form/project-form.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
    selector: "pto-add-project-species-form",
    templateUrl: "./add-project-species-form.component.html",
    styleUrls: ["./add-project-species-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProjectSpeciesFormComponent implements OnInit {
    @Output() onFormVaild = new EventEmitter();

    myInputs = { label: "Complete" };
    myInjector: Injector;
    myContent?: any[][];

    searchControl = new FormControl("");

    orginalSpeciesList: SpeciesDto[];
    speciesList: SpeciesDto[];
    selectedSpecies?: SpeciesDto;

    species$: Observable<SpeciesDto[]>;
    currentSpeciesIDs: any;
    filteredOptions: Observable<SpeciesDto[]>;

    constructor(
        private speciesService: SpeciesService,
        private cdr: ChangeDetectorRef,
        data: ProjectSpeciesIDs
    ) {
        this.currentSpeciesIDs = data;
    }

    ngOnInit(): void {
        this.filteredOptions = this.searchControl.valueChanges.pipe(
            startWith(""),
            map((value) => {
                const name = typeof value === "string" ? value : value;
                return name ? this._filter(name as string) : this.speciesList.slice();
            })
        );

        this.cdr.markForCheck();

        this.species$ = this.speciesService.speciesGet().pipe(
            map((resp) => {
                this.orginalSpeciesList = resp;
                this.speciesList = resp.filter((x) => !this.currentSpeciesIDs.includes(x.SpeciesID));
                this.cdr.markForCheck();
                return resp;
            })
        );
    }

    displayFn(species: SpeciesDto): string {
        return species && species.SpeciesCommonName ? species.SpeciesCommonName + " " + `(${species.SpeciesScientificName})` : "";
    }

    private _filter(name: string): SpeciesDto[] {
        if (typeof name === "string") {
            const filterValue = name?.toLowerCase();
            return this.speciesList.filter(
                (option) => option.SpeciesCommonName.toLowerCase().includes(filterValue) || option.SpeciesScientificName.toLowerCase().includes(filterValue)
            );
        }
    }

    selectSpecies(event: MatAutocompleteSelectedEvent) {
        this.selectedSpecies = event.option.value;
        this.cdr.markForCheck();
        this.onFormVaild.emit(true);
    }

    clearSelection() {
        this.selectedSpecies = null;
        this.searchControl.setValue("");
        this.cdr.markForCheck();
    }
}
