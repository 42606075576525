<div style="margin-top: 2rem">
    <esa-material-button type="primary" label="Select Files" icon="file_open" (click)="fileInput.click()" [disabled]="isLoading"></esa-material-button>

    <input type="file" multiple hidden (change)="onFilesSelected(fileInput.files)" #fileInput />

    <div *ngIf="filesToUpload && filesToUpload.length > 0" style="margin-top: 2rem">
        <ul style="margin-bottom: 2rem">
            <li *ngFor="let file of filesToUpload; let i = index" style="font-size: 16px; height: 32px">
                <span>{{ file.name }}</span>
                <esa-material-button
                    *ngIf="!isLoading"
                    type="icon"
                    icon="disabled_by_default"
                    (click)="spliceFiles(i)"
                    style="margin-left: 1rem"></esa-material-button>
            </li>
        </ul>

        <mat-spinner *ngIf="isLoading" style="margin: 1rem"></mat-spinner>

        <esa-material-button type="primary" label="Upload Files" icon="publish" (click)="uploadFiles()" [disabled]="isLoading"></esa-material-button>
    </div>
</div>
