<div class="list-view">
    <div class="page-header">
        <div class="page-header flex-between">
            <h1 class="page-title">Projects</h1>

            <esa-material-button *ngIf="canCreateProject" label="Project" icon="add" type="primary" (click)="navigateToProjectCreatePage()">
            </esa-material-button>
        </div>
    </div>

    <p>Projects associated with Potential-to-Occur species table.</p>

    <div class="grid-filter-bar flex-between">
        <div class="flex-start" style="width: 50%">
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Search</mat-label>
                <span matPrefix><mat-icon>search</mat-icon></span>
                <input matInput type="text" [(ngModel)]="searchGridValue" (ngModelChange)="searchGrid()" />
                <button *ngIf="searchGridValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetGrid()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="flex-end">
            <div style="border-right: 1px solid rgb(204, 203, 203)">
                <mat-label style="margin-right: 1rem">Filter:</mat-label>
                <button mat-stroked-button color="primary" style="margin-right: 1rem" (click)="filterMyProjects()" [disabled]="isActive">My Projects</button>
                <button
                    mat-mini-fab
                    color="primary"
                    aria-label="menu icon"
                    style="margin-right: 2rem"
                    [disabled]="!projectsGrid?.api?.isColumnFilterPresent()"
                    (click)="resetFilter()">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </div>

            <pto-csv-download-button
                style="margin-left: 1rem"
                [grid]="projectsGrid"
                fileName="projects"
                [colIDsToExclude]="['Tags', 'Copy']"></pto-csv-download-button>
        </div>
    </div>

    <div class="table-responsive">
        <ag-grid-angular
            #projectsGrid
            style="width: 100%; height: 100%"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [suppressMenuHide]="true"
            [animateRows]="true"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onProjectsGridReady($event)">
        </ag-grid-angular>
    </div>
</div>
